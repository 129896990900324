/* eslint-disable prettier/prettier */
import * as React from "react";
import { Link } from "gatsby";
import tw, { styled } from "twin.macro";

//! ----------> STYLES <----------
const Wrapper = styled.div`
  ${tw`w-[93%] md:(w-[90%]) lg:(w-full)`};
  ${tw`mx-auto relative`};
  ${tw`antialiased`};
`;

const Container = styled.div`
  ${tw`w-full h-full min-h-[45vh] md:(min-h-[47vh]) lg:(min-h-[41vh]) xl:(min-h-[48vh])`};
  ${tw`mx-auto px-5 md:(pt-4 px-10) xl:(px-12 pt-28 pb-24)`};
  ${tw`flex flex-col space-y-1 justify-center xl:(space-y-0)`};
  ${tw`text-dark-teal text-center`};
  --mask: 
    conic-gradient(from 132.5deg at top,#0000,#000 1deg 94deg,#0000 95deg) top/10.91px 51% repeat-x,
    conic-gradient(from -47.5deg at bottom,#0000,#000 1deg 94deg,#0000 95deg) bottom/10.91px 51% repeat-x;
  -webkit-mask: var(--mask);
          mask: var(--mask);

  h2 {
    ${tw`font-sticker uppercase`};
    ${tw`text-6xl md:(text-[110px] leading-[100px])`};
  }

  h3 {
    ${tw`font-sticker uppercase`};
    ${tw`text-base tracking-[0.05em] leading-[20px] md:(text-xl)`};
  }

  p {
    ${tw`font-display font-medium`};
    ${tw`text-base py-4 md:(text-xl py-6) lg:(text-xl pt-6 pb-4) xl:(pt-9)`};
    ${tw`md:(w-3/4 mx-auto)`};
  }
`;

const CampaignButton = styled(Link)`
  ${tw`transition duration-300 ease-in-out`};
  ${tw`bg-bourbon text-light-beige rounded-md font-bold uppercase`};
  ${tw`hover:(bg-dark-teal)`};
  ${tw`text-sm md:(text-base)`};
  ${tw`px-5 py-2 md:(px-10)`};
  ${tw`w-[fit-content] mx-auto`};
  
`;

//! ----------> COMPONENTS <----------
const CampaignModal = ({ href, close }: { href: string; close: () => void }) => {

  return (
    <Wrapper>
      <Container tw="bg-dark-teal absolute z-0" />
      <Container
        tw="relative -right-1.5 -top-2 z-10 bg-[#F0F0F0]"
        css={[`background-image: url("/img/modal-bg.png"); background-size: cover; background-repeat: no-repeat; background-position: center; `]}
      >
        <h3>There is active legislation in your state right now</h3>
        <h2>Take Action!</h2>
        <p>
          Let your legislators know you want the convenience of spirits shipped to your home.
        </p>

        <CampaignButton to={href} onClick={close}>
          See your state&apos;s campaign
        </CampaignButton>
      </Container>
    </Wrapper>
  );
};

export default CampaignModal;
