/* eslint-disable prettier/prettier */
import React from "react";
import create from "zustand";

export type State = {
  stateId: number;
  abbrv: string;
  name: string;
  jobs: string;
  gdp: string;
  exports: string;
  wine: string;
  spirits: {
    value: string;
    label: string;
  };
  spiritsText: string;
  consideringDTC: string;
};

export type Campaign = {
  name: string;
};

export type StatesStore = {
  states: State[];
  currentState?: State | null;
  setStates: (arg: State[]) => void;
  setCurrentState: (arg?: State) => void;
  campaigns: string[];
  setCampaigns: (campaigns?: string[]) => void;
};

export const useStateStore = create<StatesStore>()((set) => ({
  states: [],
  currentState: undefined,
  setStates: (states: State[]) => set({ states }),
  setCurrentState: (state?: State) => set({ currentState: state }),
  campaigns: [],
  setCampaigns: (campaigns?: string[]) => set({ campaigns }),
}));