import { StateDistrict } from "./states";

const numberOfCraftDistillersPerState: {
	[key in StateDistrict]: number;
} = {
	AK: 12,
	AL: 23,
	AR: 10,
	AZ: 30,
	CA: 190,
	CO: 107,
	CT: 19,
	DC: 9,
	DE: 5,
	FL: 67,
	GA: 35,
	HI: 19,
	IA: 21,
	ID: 15,
	IL: 58,
	IN: 48,
	KS: 11,
	KY: 54,
	LA: 25,
	MA: 37,
	MD: 38,
	ME: 26,
	MI: 88,
	MN: 38,
	MO: 60,
	MS: 4,
	MT: 29,
	NC: 80,
	ND: 6,
	NE: 12,
	NH: 20,
	NJ: 30,
	NM: 20,
	NV: 21,
	NY: 180,
	OH: 73,
	OK: 13,
	OR: 77,
	PA: 117,
	RI: 6,
	SC: 38,
	SD: 10,
	TN: 57,
	TX: 135,
	UT: 20,
	VA: 59,
	VT: 22,
	WA: 135,
	WI: 48,
	WV: 20,
	WY: 13,
};

export default numberOfCraftDistillersPerState;
