/* eslint-disable react/no-unknown-property */
/* eslint-disable prettier/prettier */
import { useState, useEffect } from "react";
import tw, { css } from "twin.macro";

import { useStateStore } from "../../util/store/state-store";
import Hint from "../../assets/svgs/hint.inline.svg";
import Wine from "../../assets/svgs/wine-icon.inline.svg";
import Spirit from "../../assets/svgs/spirit-icon.inline.svg";

const DtcFactsBox = ({
	showHint = false,
}: {
	showHint?: boolean;
}) => {
	const { currentState } = useStateStore();
	const [loaded, setLoaded] = useState<boolean>(false);

	useEffect(() => {
		if (currentState) {
			console.log(currentState)
			setLoaded(true);
		}
	}, [currentState]);

	return loaded ? (
		<div tw="border-dark-teal border-width[1px] p-4 mb-12 md:(width[45%] mr-4)">
			<h3 tw="uppercase font-sticker text-dark-teal mb-4">
				Is DTC Shipping Allowed?
			</h3>
			<div tw="border-dark-teal border-width[1px] mb-4 height[168px] grid grid-template-rows[1fr 2fr]">
				<div tw="h-full flex items-center pl-4 ">
					<Wine />
					<h3 tw="text-dark-teal text-lg font-medium block -mb-1.5 ml-2">
						Wine
					</h3>
				</div>
				<div tw="border-dark-teal border-top-width[1.5px] px-4 h-full flex flex-col justify-center">
					<h1 tw="font-alternate text-5xl lg:text-6xl">
						{currentState?.wine}
					</h1>
				</div>
			</div>
			<div tw="border-dark-teal border-width[1px] mb-4 height[168px] grid grid-template-rows[1fr 2fr]">
				<div tw="h-full flex items-center pl-4">
					<Spirit />
					<h3 tw="text-dark-teal text-lg font-medium block -mb-1.5 ml-2">
						Spirits
					</h3>
				</div>
				<div
					css={[
						tw`border-dark-teal border-top-width[1.5px] px-4 h-full flex`,
						tw`flex-col justify-center`,
					]}
				>
					<h1 tw="font-alternate text-5xl lg:text-6xl">
						{
							currentState?.spirits.label
						}
					</h1>

					<div tw="flex">
						<h3
							css={[
								tw`font-sticker uppercase mt-3 flex items-center lg:mt-0`,
							]}
						>
							{currentState?.spirits.value === `0` && currentState?.spiritsText}
							{` `}
						</h3>
						{showHint && (
							<div
								css={[
									tw`relative`,
									css`
										span {
											display: none;
										}

										:hover span {
											display: inline-block;
										}
									`,
								]}
							>
								<Hint tw="ml-2" />
								<span tw="absolute bottom-0 left-0 translate-x-1/4 bg-bitters text-light-beige px-4 pt-1.5 rounded font-medium">
									out&#8209;of&#8209;state&nbsp;only
								</span>
							</div>
						)}
					</div>
				</div>
			</div>
		</div>
	) : (
		<div>Loading...</div>
	);
};

export default DtcFactsBox;
