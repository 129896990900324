/* eslint-disable prettier/prettier */
/* eslint-disable react/no-unknown-property */
import { useState, ChangeEvent } from "react";
import { navigate } from "gatsby";
import "twin.macro";

import StateDropdown from "../forms/state-dropdown";
import { StateDistrict } from "../../util/data/states";

const TopSelectorSection = () => {
	const [selected, setSelected] = useState<StateDistrict | null>(null);

	const handleSubmit = () => {
		if (selected) {
			navigate(`/state-facts?state=${selected}`);
		}
	};

	const handleChange = (e: ChangeEvent<HTMLSelectElement>) => {
		setSelected(e.target.value as StateDistrict);
	};

	return (
		<div tw="max-width[875px] w-full lg:(flex justify-between width[70%] mt-28)">
			<h1 tw="font-sticker text-3xl lg:text-4xl">
				In Your State
			</h1>
			<div tw="lg:max-width[400px]">
				<p tw="text-2xl mt-6 mb-8 lg:(mt-0 font-medium)">
					Find out what&apos;s going on in your state and take
					action today!
				</p>
				<StateDropdown
					colorTheme="cool"
					onSubmitHandler={handleSubmit}
					handleChange={handleChange}
				/>
			</div>
		</div>
	);
};

export default TopSelectorSection;
