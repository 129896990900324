/* eslint-disable unicorn/prefer-array-some */
/* eslint-disable react/no-unknown-property */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable prettier/prettier */
/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import { graphql } from "gatsby";
import queryString from "query-string";
import tw, { css } from "twin.macro";
import Popup from "reactjs-popup";

import { useStateStore } from "../../util/store/state-store";
import { useSingleStateQuery } from "../../util/queries";
import SEO from "../../components/util/SEO";
import Layout from "../../components/util/Layout";
import NavLayout from "../../components/layout/nav-layout";
import TopSelectorSection from "../../components/state-facts/top-selector-section";
import InteractiveMapSection from "../../components/state-facts/interactive-map-section";
import News from "../../components/news/news";
import CampaignModal from "../../components/state-facts/modal";

import { slugify } from "../../util/helpers/slugify";

import decal from "../../assets/images/state-facts-decal.png";
import { StateDistrict } from "../../util/data/states";

type PageProps = {
	location: Location;
	data: {
		allSanityCampaign: {
			nodes: {
				name: string;
			}[];
		};
		statePDFS: {
			nodes: {
				name: string;
				pdf?: {
					asset: {
						url: string;
					};
				};
			}[];
		};
	};
};

const StateFactsPage = ({
	location,
	data
}: PageProps) => {
	const [open, setOpen] = useState<boolean>(false);
	const stateString = queryString.parse(location.search).state;
	const slug = slugify(stateString as StateDistrict);

	const { setCurrentState, currentState } = useStateStore();
	const { data: stateData, isLoading } = useSingleStateQuery(slug);

	const activeCampaigns = data?.allSanityCampaign.nodes;
	const active = activeCampaigns.map((state) => state.name);
	const states = data?.statePDFS?.nodes;
	const statePDF = states.find((x) => x.name === currentState?.abbrv);

	const close = () => setOpen(false);

	useEffect(() => {
		if (!stateString) {
			setCurrentState();
			close();
		}

		if (active.find((state) => state === stateString as string)) {
			setOpen(true);
		}

		if (!isLoading && stateString) {
			const state = stateData[0];
			setCurrentState({
				stateId: state.id,
				abbrv: state.ACF.state_id,
				name: state.title.rendered,
				jobs: state.ACF.jobs_supported,
				gdp: state.ACF.state_gdp,
				exports: state.ACF.state_export_data,
				wine: state.ACF.yes__no.allowing_wine_dtc_shipping,
				spirits: {
					label: state.ACF.yes__no.allowing_spirits_dtc_shipping.label,
					value: state.ACF.yes__no.allowing_spirits_dtc_shipping.value,
				},
				spiritsText: state.ACF.yes__no.spirits_dtc_text,
				consideringDTC: state.ACF.yes__no.considering_dtc_legislation,
			});
		}
	}, [isLoading, stateString]);

	return (
		<Layout>
			<SEO />
			<Popup
				modal
				open={open}
				onClose={() => setOpen(false)}
				overlayStyle={{ background: `rgba(0, 0, 0, 0.5)` }}
				closeOnDocumentClick
				lockScroll
			>
				<CampaignModal href={`/take-action/${stateString}`} close={close} />
			</Popup>
			{!isLoading ? (
				<NavLayout activePage="state-facts">
					<div
						css={[
							tw`h-screen md:(h-auto min-height[450px])`,
							tw`font-display text-bitters`,
							tw`bg-light-beige`,
							tw`relative`,
							tw`px-8 py-8`,
							tw`flex flex-col justify-center items-center`,
							css`
									background-image: url(${decal});
									background-size: 60px;
									background-repeat: no-repeat;
									background-position: right bottom;
									background-origin: content-box;
									/* @media (min-width: 1024px) {
								background-size: 50px;
							} */
	`,
						]}
					>
						<TopSelectorSection />
					</div>
					<InteractiveMapSection isActive={!!active.find((state) => state === stateString as string)} pdf={statePDF?.pdf?.asset.url} />
					<News />
				</NavLayout>
			) : <div>Loading...</div>}
		</Layout>
	);
};

export const query = graphql`
	query {
		allSanityCampaign(filter: { type: { eq: "active" } }) {
			nodes {
				name
			}
		}
		statePDFS: allSanityCampaign {
			nodes {
				name
				pdf {
					asset {
						url
					}
				}
			}
		}
	}
`;

export default StateFactsPage;