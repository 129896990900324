import { StateDistrict, stateNames } from "../data/states";

export const slugify = (stateAbbrv: StateDistrict) => {
	return stateAbbrv === `DC`
		? `washington-d-c`
		: stateAbbrv === `AL`
		? `alabalma`
		: stateAbbrv === `TX`
		? `texas-2`
		: stateNames[stateAbbrv]
				?.toLowerCase()
				.split(` `)
				.join(`-`);
};
