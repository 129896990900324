/* eslint-disable prettier/prettier */
/* eslint-disable react/no-unknown-property */
import React from "react";
import tw from "twin.macro";

import Map from "./map";

const MapInteractive = () => {
	return (
		<div
			css={[
				tw`flex flex-col items-center justify-center w-full`,
				tw`bg-mist`,
			]}
		>
			<div tw="w-full">
				<Map />
			</div>
		</div>
	);
};

export default MapInteractive;
